/***********************************************/
// Capa de Servicios para el modulo de Ciudades.

import axios from "axios";
import store from "@/store/store.js";

export default new class {

    #baseUrl = null;
    errormsg = '';
    message = null;
    header = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    };

    constructor() {
        // Inicializar campos y propiedades.
        this.#baseUrl = store.getters.getBaseURL;
    }

    async reload() {
        this.header = {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        };
    }

    async getProfiles() {
        try {
            await this.reload();
            let response = await axios.get(this.#baseUrl + "/profiles", {
                headers: this.header
            });
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getCountRecords(module) {
        try {
            await this.reload();
            let response = await axios.get(`${this.#baseUrl}/count-records/${module}`, {
                headers: this.header
            });
            return response.data.value;
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getTotalDoctorsSpecialties() {
        try {
            await this.reload();
            let response = await axios.get(this.#baseUrl + "/count-records/specialties", {
                headers: this.header
            });
            return response.data.value;
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getCountRecordsTags() {
        try {
            await this.reload();
            let response = await axios.get(`${this.#baseUrl}/count-records/preferences`,{
                headers: this.header
            });
            return response.data.value;
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getCountTags(tag_type) {
        try {
            await this.reload();
            let formData = new FormData();
            formData.append("tag", tag_type);
            let response = await axios.get(`${this.#baseUrl}/count-tag/preferences?tag=${tag_type}`, {
                headers: this.header
            });
            return response.data.value;
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getTotaltVisits() {
        try {
            await this.reload();
            let response = await axios.get(`${this.#baseUrl}/total-visits/visits`, {
                headers: this.header
            });
            return response.data.value;
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getCountVisits(device_type) {
        try {
            await this.reload();
            let formData = new FormData();
            formData.append("device", device_type);
            let response = await axios.get(`${this.#baseUrl}/count-visits/visits?device=${device_type}`, {
                headers: this.header
            });
            return response.data.value;
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getRecientContacts() {
        try {
            await this.reload();
            let response = await axios.get(this.#baseUrl + "/view-list-recents/contacts", {
                headers: this.header
            });
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getRecientComments() {
        try {
            await this.reload();
            let response = await axios.get(this.#baseUrl + "/view-list-recents/comments", {
                headers: this.header
            });
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

}